@import "_variables";
.experiencia {
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.companies {
  display: flex;
  align-items: center;
  padding: 0px;
  list-style: none;
  overflow-x: auto;
  justify-content: flex-start;

  li {
    margin-bottom: 10px;
    flex: none;
    &:not(:last-of-type) {
      margin-right: 38px;
    }
  }
  &__button {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
    color: $cinza;
    background: none;
    border: 0px;
    font-size: 1.5rem;
    padding: 0px 0px 5px 0px;
    margin: 0px;
    &.active {
      font-weight: 700;
      color: $laranja;
      border-bottom: 5px solid $laranja;
      padding: 0px;
    }
  }
  &::-webkit-scrollbar {
    @include scrollbar;
  }
}

@media screen and (min-width: 1024px) {
  .experiencia {
    &__content {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }
  .companies {
    flex-direction: column;
    align-items: flex-end;
    width: 30%;
    padding: 0px;
    margin-right: 2.188rem;
    margin-top: 0px;
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: flex-start;
    li {
      flex: none;
      margin-left: 0;
      margin-right: 0px !important;
    }
  }
  .job {
    margin-top: 0px;
    width: 70%;
    &__attribs {
      width: 100%;
    }
  }
}
