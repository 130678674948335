.job {
  margin-top: 1.625rem;

  &__cargo {
    font-size: 1.25rem;
    margin-top: 0px;
    margin-bottom: 1.313rem;
  }
  &__periodo {
    margin-bottom: 0.875rem;
    font-size: 1.25rem;
  }
  &__attrib {
    font-size: 1.25rem;
    margin-bottom: 0.875rem;
    &s {
      padding: 0px 1.25rem;
    }
  }
}
@media screen and (min-width: 1024px) {
  .job {
    margin-top: 0px;
    width: 70%;
    &__attribs {
      width: 100%;
    }
  }
}
