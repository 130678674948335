@import "_variables";

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  color: $cinza;
  background-color: $purpura;
  &::-webkit-scrollbar {
    @include scrollbar;
    width: 12px;
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: content-box;
  }
}

header,
section {
  min-height: calc(100vh - 7.438rem);
  padding-top: 8rem;
  @include section-padding;
}
@media screen and (min-width: 1024px) {
  header,
  section {
    min-height: calc(100vh - 5.25rem);
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 1440px) {
  :root {
    font-size: 20px;
  }
}
@media screen and (min-width: 1920px) {
  :root {
    font-size: 26px;
  }
}
@media screen and (min-width: 3840px) {
  :root {
    font-size: 32px;
  }
}
// @import "Header.scss";
// @import "Menu.scss";
// @import "Section.scss";
@import "Sobre.scss";
@import "ExpProf.scss";
@import "Projeto.scss";
@import "Contato.scss";
