@import "_variables";
.contato {
  display: flex;
  padding-top: 1.625rem;
  min-height: calc(100vh - 5.438rem);
  justify-content: flex-start;
  &__text {
    margin-bottom: 2rem;
  }
  &__email {
    border: 3px solid $laranja;
    border-radius: $radius;
    color: $laranja;
    text-decoration: none;
    padding: 0.5rem 1.313rem;
    margin-bottom: 2rem;
  }
  &__externals {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $laranja;
      code {
        margin-top: 0.5rem;
      }
    }

    .bi {
      height: 4.375rem;
      color: $laranja;
    }
  }
}

@media screen and (min-width: 1024px) {
  .contato {
    display: flex;
    flex-direction: column;
    .title {
      justify-content: center;
      &__line {
        display: none;
      }
    }
    &__text,
    &__externals {
      width: calc(100% / 3);
    }
    &__text {
      text-align: center;
    }
  }
}

@media screen and (min-width: 1920px) {
  .contato {
    &__email {
      margin-bottom: 1.25rem;
    }
    &__text {
      margin-bottom: 1rem;
    }
    &__externals {
      .bi {
        height: 3.375rem;
      }
    }
  }
}
