@import "_variables";
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.688rem;
    &__text {
      font-size: 2rem;
      margin: 0px;
    }
    &__line {
      display: none;
    }
    code {
      font-size: 1.5rem;
      color: $laranja;
    }
  }
  p {
    font-size: 1.25rem;
    margin-top: 0px;
  }
}
@media screen and (min-width: 768px) {
  section {
    .title {
      font-size: 0;
      &__text {
        width: min-content;
        min-width: fit-content;
      }
      &__line {
        display: block;
        height: 1px;
        width: inherit;
        margin-left: 1.125rem;
        background-color: $cinza;
        border: 0.188rem solid $cinza;
        border-radius: $radius;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  section {
    .title {
      &__line {
        display: block;
      }
    }
  }
}
