@import "_variables";
.sobre {
  &__article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  &__profile {
    width: 124px;
    height: 124px;
    border-radius: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .sobre {
    &__article {
      flex-direction: row;
      width: 100%;
      &--text {
        width: 55vw;
      }
    }
    &__profile {
      width: 30vw;
      max-width: 23.25rem;
      height: auto;
    }
  }
}
