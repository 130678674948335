@import "_variables";
.projetos {
  &__list {
    padding: 0px;
    display: flex;
    overflow-x: scroll;
    align-items: center;
    margin-top: 0;
    width: 100%;
    &::-webkit-scrollbar {
      @include scrollbar;
    }
  }
}
.projeto {
  &:not(:first-of-type) {
    margin-left: 38px;
  }
  &:not(:last-of-type) {
    margin-right: 38px;
  }
  list-style: none;
  flex: none;
  margin-bottom: 2rem;
  width: 100%;
  &__screenshot {
    width: auto;
    max-height: 7.2rem;
    border-radius: $radius;
    margin-bottom: 0;
  }
  &__descricao {
    background-color: $verde;
    padding: 1.125rem;
    border-radius: $radius;
    box-shadow: $sombra;
    margin-bottom: 1.5rem;
    h2 {
      margin-top: 0px;
      font-weight: 700;
    }
  }
  .tags {
    list-style: none;
    display: flex;
    padding: 0px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    &__item {
      background-color: $verde;
      border-radius: $radius;
      box-shadow: $sombra;
      padding: 0.313rem;
      &:not(:last-of-type) {
        margin-right: 1.375rem;
      }
      margin-bottom: 1.5rem;
    }
  }
  &__links {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $laranja;
      code {
        margin-top: 0.5rem;
      }
    }
    .bi {
      height: 4.375rem;
      color: $laranja;
    }
  }
}

@media screen and (min-width: 1024px) {
  .projeto {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 70%;
    margin: 0 15% 2rem 15% !important;
    &__screenshot {
      grid-area: 1 / 1 / 2 / 5;
      width: 100%;
      max-height: initial;
      border: 0.125rem solid $cinza;
      box-shadow: $sombra;
    }
    &__dados {
      grid-area: 1/3/2/7;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;
    }
    &__links {
      &--logo {
        &:not(:last-of-type) {
          margin-right: 1.625rem;
        }
      }
    }
  }
}
