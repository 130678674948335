$purpura: #352f52;
$cinza: #fff;
$verde: #006565;
$laranja: #f59d18;
$sombra: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
$radius: 0.375rem;
@mixin scrollbar {
  background-color: #8173c2;
  height: 0.4rem;
  border-radius: $radius;
  &-thumb {
    background-color: $laranja;
    border-radius: $radius;
  }
}
@mixin section-padding {
  padding-left: 2.625rem;
  padding-right: 2.625rem;
}
