@import "_variables";
.navbar {
  min-height: 4.375rem;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: $purpura;
  padding: 0px 0.625rem;
  box-shadow: 0px 20px 0.875rem 0.125rem #352f52;
  align-items: center;
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  &__logo {
    font-size: 1.25rem;
    width: 4.5rem;
    code {
      color: $laranja;
    }
  }
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  &__menu {
    margin: 0;
    padding: 0px;
    display: none;
    width: 0;
    height: 0;
    &.open {
      position: fixed;
      background-color: $purpura;
      width: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
    .bi {
      color: $laranja;
      height: 4.375rem;
    }
    li {
      list-style: none;
      font-size: 1.5rem;
      margin-bottom: calc(50vh / 5);
    }
  }
  button {
    min-width: 34px;
    min-height: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    border: 0px;
    background: none;
    svg {
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }

  a {
    span {
      color: $laranja;
    }
    text-decoration: none;
    color: $cinza;
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include section-padding;
    &__menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      &.open {
        position: static;
        justify-content: flex-end;
        flex-direction: row;
        height: auto;
      }
      li {
        margin-bottom: 0px;
        &:not(:last-of-type) {
          margin-right: 1.688rem;
        }
      }
      .navbar__button--close {
        display: none;
      }
    }
    &__button[type="button"] {
      display: none;
    }
  }
}
