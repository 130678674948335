@import "_variables";
header {
  @include section-padding;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 3.125rem;
    margin-top: 0px;
    small,
    code {
      display: block;
    }
    small {
      font-size: 2rem;
      margin-top: 1.313rem;
    }
    code {
      font-size: 1.5rem;
      color: $laranja;
    }
  }
  p {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1024px) {
  header {
    h1 {
      font-size: 5rem;
      line-height: 5.875rem;
      small {
        font-size: 5rem;
        margin-top: 2rem;
        line-height: 2.25rem;
      }
      code {
        line-height: 1.875rem;
      }
    }
    p {
      line-height: 2.375rem;
      width: 54vw;
    }
  }
}
